.box-nav {
  padding-bottom: 0;
}

ul.comment-list {
  margin:  0;
  list-style:  none;
  padding: 1rem;
}

li.comment-item {
  border-bottom:  1px solid #f4f4f4;
  margin-bottom: 2rem;
}

.comment-item > h5 {
  font-weight:  600;
  margin-bottom:  0.3rem;
}