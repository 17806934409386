/* Dashboard Boxen */

.dash .bg-green a h4,
.dash .bg-aqua a h4,
.dash .bg-yellow a h4,
.dash .bg-green a p,
.dash .bg-aqua a p,
.dash .bg-yellow a p,
.dash .bg-green a:hover i,
.dash .bg-aqua a:hover i,
.dash .bg-yellow a:hover i {
  color: #fff;
}

.dash .bg-green a i {
  color: #00a65a;
}

.dash .bg-aqua a i {
  color: #00c0ef;
}

.dash .bg-yellow a i {
  color: #f39c12;
}

.info-box-icon {
  font-size: 5.75rem;
}

.info-box-content {
  padding: 1.8rem;
}

.info-box-content h4 {
  margin-top: 0;
  font-weight: 700;
}

.info-box-content p {
  font-size: 1.6rem;
  margin-bottom: 0;
}
