.menu-list {
  list-style: none;
  -moz-padding-start: 0;
  -webkit-padding-start: 0;
}
.menu-list--elment {
  border: 1px solid #ddd;
  position: relative;
  padding: 5px 15px;
  height: auto;
  min-height: 20px;
  width: 382px;
  line-height: 30px;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 1rem;
  background: white;
}
.menu-back--wrapper {
  border: 1px solid #ddd;
  padding: 1rem;
}