/* Bootstrap überschreiben */
.content-header {
  min-height: 50px;
  padding: 10px 15px 10px 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ffffff !important;
}

.content-header > .breadcrumb {
  top: 8px;
}

.panel, .panel-heading, .btn, .form-control, .alert, .pagination, .pagination *, .modal-content, .progress {
  border-radius: 0;
}

.panel-footer {
  border-top: none;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 0;
}

.form-control, .panel {
  box-shadow: none;
}

.alert, .btn {
  border: none;
}

.kdz-help-block {
  font-size: 12px;
}

.database-alert {
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  background: red !important;
  font-family: Open Sans, sans-serif;
  font-weight: 500;
  font-size: 16px;
}