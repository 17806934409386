/* Filter */
#filter {
  position: fixed;
  width: auto;
  background: #c0c0c0;
  padding: 5px 30px;
  top: 50px;
  left: 250px;
  right: 0;
}

#importContent, #filterContent {
  width: auto;
  margin: 0px 0px 20px 0px;
}

.btnBar {
  margin: 0px 16px 0 0px;
  float: left;
  border-right: 1px dashed #aaa;
}

.btnBar.date {
  margin: 0px 16px 10px 0px;
  clear: both;
}

.btnGroup {
  margin: 0px 6px 0px 0px;
  float: left;
}

.btnGroup.last {
  margin-right: 16px;
  float: left;
}

.btnGroup .btn {
  min-width: 32px;
  margin: 0px 2px 0px 0px;
  padding: 6px;
  float: left;
}

.btnGroup .text {
  margin-top: 5px;
  float: left;
}

.btnGroup .form-control {
  height: 28px;
  margin-top: 1px;
  text-align: center;
  float: left;
}

.btnGroup .form-control.datum {
  width: 130px;
}

.btnGroup .btn.set,
.btnGroup .btn.active {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5) inset;
}

.content-header .breadcrumb {
  display: none;
}

.bs-actionsbox .btn-group {
  display:  flex;
  justify-content:  space-between;
  padding:  0;
}

.bootstrap-select.btn-group .dropdown-menu {
  max-height: 80vh !important;
}
/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {

}

/* viewport-lg 992px */
@media screen and (min-width: 62rem) {
  .content-header .breadcrumb {
    display: block;
  }

  .fixed .content-wrapper .content-header {
    width: 100%;
    z-index: 100;
    margin-left: -230px;
  }

  .fixed .content-wrapper .content-header .btn-toolbar {
    margin-left: 230px;
  }
}

/* viewport-lg 1200px */
@media screen and (min-width: 75rem) {
}

/* viewport-xxl 1520px */
@media screen and (min-width: 95rem) {
}

div#filterContent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0;
  padding: 10px;
  transform: translate(0, 100%);
  transition: all 0.5s ease;
  background: none repeat scroll 0 0 #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

div#filterContent.open {
  transform: translate(0);
}