/* Formular allgemein */
.kdz-checkbox input {
    margin-left:0 !important;
}
.kdz-checkbox .help-block {
    margin-left:20px;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.kdz-fehlerfeld, input.errorClass, select.errorClass {
    border-color: #900;
}
label.errorClass {
    color: #900;
}
.kdz-form-radio-inline .radio {
  text-align: center;
  display: inline-block;
}

.form-group .bootstrap-tagsinput {
  width: 100%;
}