.site-plugins {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.site-plugins--box {
  padding: 1rem 0.5rem 1rem 2rem ;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  min-height: 6rem;
  width: 45%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  /*border: solid 1px #1e282c;*/
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.22);
  background: rgba(238, 238, 238, 0.17);
}
.site-plugins--box:hover {
  cursor: pointer;
  background: #00a65a;
}
.site-plugins--img i{
  margin-right: 1rem;
}
.content-img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.content-img--box {
  width: 45%;
  margin-right: 1rem;
  margin-top: 1rem;
}

.filter-list {
  list-style: none;
  -moz-padding-start: 0;
  -webkit-padding-start: 0;
}
.filter-list--elment {
  border: 1px solid #ddd;
  position: relative;
  padding: 5px 15px;
  height: auto;
  min-height: 20px;
  width: 382px;
  line-height: 30px;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 1rem;
  background: white;
}
.filter-back--wrapper {
  border: 1px solid #ddd;
  padding: 1rem;
}