.timer--header ul.dropdown-menu {
  width:  300px;
  padding:  10px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.timer--header .btn-group > button {
  width: 30%;
  margin-right: 10px;
}
.timer--header .btn-group > button:last-child {
  margin-right: 0;
}
.timer--header .btn-group {
  width: 100%;
}

div#stoptimer {
  font-size: 17px;
  color:  white;
  font-weight:  bold;
  margin:  auto;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0px;
  background:  red;
  padding: 2px 5px;
  letter-spacing: 4px;
}

li.timer-header {
  display:  flex;
  justify-content:  center;
  align-items:  center;
  align-content:  center;
  font-size:  18px;
  color:  white;
  margin-top: 5px;
}

li.timer-header span {
  margin-right: 10px;
}

.timer-header .btn-group .btn {
  margin-right: 5px;
}