ul.details.col-md-12 {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

ul.details.col-md-12 li {
  padding: 7px 10px;
  border-bottom: 1px solid #ECECEC;
  min-height: 20px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

ul.details.col-md-12 li:last-child {
  border-bottom: none;
}

ul.details.col-md-12 li span {
  display: block;
  margin-bottom: 2px;
  color: #A4A5A9;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: .3px;
}

.popover.confirmation {
  width: 200px;
}

.modal-content ul.details {
  list-style:  none;
  padding-left: 15px;
}

.modal-content ul.details > li > span {
  font-weight: bold;
}