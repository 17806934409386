.sort-list-style,
.boxen-list {
  list-style: none;
  -moz-padding-start: 0;
  -webkit-padding-start: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.boxen-list--sort {
  width: 100%;
}
.boxen-list--sort.active .boxen-list--elment {
  background: #eeeeee;
}
.boxen-list--elment {
  border: 1px solid #ddd;
  position: relative;
  padding: 5px 15px;
  height: auto;
  min-height: 100px;
  width: 100%;
  line-height: 16px;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 1rem;
  background: white;
}
.boxen-back--wrapper {
  border: 1px solid #ddd;
  padding: 1rem;
}
@media screen and (min-width: 35.5rem) {
  .boxen-list--sort {
    width: 100%;
  }
}
@media screen and (min-width: 48rem) {
  .boxen-list--sort {
    width: 49%;
    margin-right: 5px;
  }
}
@media screen and (min-width: 64rem) {
  .boxen-list--sort {
    width: 32%;
    margin-right: 5px;
  }
}
@media screen and (min-width: 80rem) {
  .boxen-list--sort {
    width: 24%;
    margin-right: 5px;
  }
}
