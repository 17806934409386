.input-image-dropdown {
  margin-bottom: 1rem;
}
.input-image-dropdown .dropdown-toggle, .input-image-dropdown .dropdown-menu {
  width: 100%;
}
.input-image-dropdown .btn-group {
  width: 100%;
}
.input-image-dropdown .btn-group img {
  margin-right: 10px;
  max-width: 50px;
}

.input-image-dropdown .dropdown-toggle {
  padding-right: 50px;
  padding-left: 20px;
}

.input-image-dropdown .dropdown-toggle .glyphicon {
  margin-left: 20px;
  margin-right: -40px
}

.input-image-dropdown .dropdown-menu > li > a:hover {
  background: #f1f9fd
}

/* $search-blue */
.input-image-dropdown .dropdown-header {
  background: #ccc;
  font-size: 14px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 5px
}
.input-image-dropdown-wert {
  text-align: left;
  display: inline-block;
  width: 100%;
}
.input-image-dropdown ul.dropdown-menu {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e6e6e6;
}