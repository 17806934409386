/*Fotoupload*/
.contentFotoBox .contentFotoBoxImg{
    max-width:150px;
    border:1px solid #ccc;
    background:#fff;
    padding:4px;
}

/* Fotoauswahl */
.showFotoSelectBox {
    margin-bottom:15px;
}
.insertFotoBox .rfFoto {
    width:50%;
    margin-right:10px;
    display:inline-block;
}
.fotoPreviewContainer {
    margin-bottom:15px;
}

/* Fotoalbum */
.img_thumbs_p span {
    float:left;
}
.img_thumbs_p .title {
    width:100px;
    clear:left;
}
.fotobox {
    border:1px solid #ddd;
    padding:10px;
    margin-bottom:30px;
    background:#f5f5f5;
}
.img_thumbs .imgLink {
    display:block;
    height:200px;
}
.img_thumbs .imgLink img {
    max-width:100%;
    max-height:200px;
}
.fotoDelOk {
    padding: 3px;
    margin-bottom: 10px;
    background: #ccc;
    color: white;
    text-align: center;
}
.fotoDelNot {
    padding: 3px;
    margin-bottom: 10px;
    background: #d9534f;
    color: white;
    text-align: center;
}
#fotoBoxList > li {
    list-style: none;
}
/* Foto */
#fotoBoxList > .col-lg-3:nth-child(4n+1) {
    clear:left;
}
.delete-foto {
    position: absolute;
    left: 0;
    bottom: 0;
}
.foto-container{
    position: relative;
}
.single-foto-delete,
.selectedFotoDelete {
  position: absolute;
  bottom: 0;
  left: 15px;
}