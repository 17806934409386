
.calendar--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.calendar-content {
  width: 100%;
}

.calendar-colum {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: stretch;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.calendar-colum-header {
  background: #eee;
  width: 100%;
  padding: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.calendar-colum-header:first-child {
  text-align: left;
}

.calendar-colum-item {
  border: #eee solid 1px;
  width: 100%;
  max-width: 100%;
  padding: 0.5rem;
}

.projekt-boxlist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.box.box--planung {
  /*max-height: 400px;*/
  /*!* height: 111px; *!*/
  /*overflow-y: auto;*/
  /*overflow-x:hidden;*/
}
.projekt-box {
  padding: 1rem;
  background: #3c8dbc;
  margin: 0;
  margin-bottom: 1rem;
  color: white;
  z-index: 30;
  width: 200px;
  height: 48px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-right: 0.4rem;
  font-size: 12px;
}

.sortable {
  list-style: none;
  padding: 0.5rem;
  margin: 0;

}

.projekt-box div.btn-group {
  padding: 0;
  margin: 0;
  display: flex;
}

.ui-helper {
  width: 100% !important;
}

.popover-title {
  color: #333 !important;
}
.copy-day {
  cursor: pointer;
  margin-bottom: 1rem;
  margin-left: 10px;
}
.free-file  {
  cursor: pointer;
  margin-bottom: 1rem;

}

.copy-day-mit{
  cursor: pointer;
  margin-bottom: 1rem;
}
