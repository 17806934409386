/* Inhalte */
.layout {
    text-align:center;
}
.layout label {
    display:block;
    margin-bottom:0;
}
.layout label img {
    margin:0 auto;
}

.dataTable_length {
  margin-top: 5px;
}

#formTable .table > thead > tr > th,
#formTable .table > tbody > tr > th,
#formTable .table > tfoot > tr > th,
#formTable .table > thead > tr > td,
#formTable .table > tbody > tr > td,
#formTable .table > tfoot > tr > td {
  text-align: left;
}

#formTable .kdz-table-responsive {
  padding-top: 10px;
}
#formTable  .btn-group > .btn, #formTable  .btn-group-vertical > .btn {
  float: none;
}
#formTable  .btn-group, #formTable  .btn-group-vertical {
  white-space: nowrap;
}
/* viewport-xxl 768px */
@media screen and (min-width: 48rem) {
  #formTable .kdz-table-responsive {
    padding-top: 0;
  }
}

/* viewport-xxl 1520px */
@media screen and (min-width: 95rem) {
  #formTable .kdz-table-responsive {
    overflow-x: unset;
  }
}
