/* allgemeine Infos und viewport-sx 320px */
.main-header {
  max-height: 12.5rem;
}

.main-header .logo {
  height: 7.2rem;
}

.main-header .logo-lg {
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.main-header .logo-lg img{
  width: auto;
  height: 100%;
}

.main-sidebar, .left-side {
    padding-top: 12.5rem;
}
.btn-group-table .btn + .btn {
  margin-left: 2px;

}
.login-logo {
  max-height: 200px;
  position: relative;
  display: block;

}
.login-logo a{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}
.login-logo img{
  max-height: 200px;
  display: block;
  width: auto;
}

.modal-header {
  background: #3c8dbc;
  color: white;
}
.modal-header .close {
  color: white;
  opacity: 1;
}
/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .main-header {
    max-height: 10rem;
  }

  .main-header .logo {
    height: 5rem;
  }

  .main-header .logo-lg {
    padding-top: 0;
    padding-bottom: 0;
  }

  .main-header .logo-lg img {
    vertical-align: top;
    padding-top: 0.5rem;
  }

  .main-sidebar, .left-side {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
}

/* viewport-lg 992px */
@media screen and (min-width: 62rem) {
}
