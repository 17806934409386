.no-background{
  background: 0;
}

.modal {
  position: relative;
}
.modal-dialog {
  position: fixed;
  margin: 0;
  padding: 10px;
}

.modal-content {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.3);
  max-height: 100vh;
  /*overflow: scroll;*/
  transition: all 0.5s ease-in;
}
.modal-body {
  max-height: 82vh;
  overflow: auto;
}
.modal.fullscreen .modal-dialog {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh!important;
}
.modal.fullscreen .modal-content {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh!important;
}
@media screen and (min-width: 768px) {
  .modal-content {
    max-height: 95vh;
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.3);
  }
}