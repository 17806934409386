.cal-legend {
  display:  flex;
  justify-content: space-between;
  align-items:  center;
  align-content:  center;
  margin-top: 3rem;
}

.cal-legend--titel {
  color:  #3c8dbc;
  font-size:  16px;
  font-weight:  bold;
}

.cal-legend--elements {
  display:  flex;
  justify-content: flex-end;
  align-items:  center;
}

.cal-legend--elements > span {
  color: #3c8dbc;
  font-weight:  bold;
  margin-right: 15px;
}

.cal-legend--element {
  display:  flex;
  justify-content:  center;
  align-items:  center;
  align-content:  center;
  margin-right: 15px;
}
.cal-table {
  margin-top: 2rem;
}

.cal-table thead td {
  background: #3c8dbc;
  color: white;
  text-align: center;
}

.cal-table td {
  border: solid 2px white;
  text-align: center;
}
.cal-table tbody td {
  background: rgba(169, 169, 179, 0.3);
}
.is--box {
  height: 25px;
  width: 23px;
  margin-right: 5px;
  background:  #333;
  color: white;
  display:  flex;
  justify-content:  center;
  align-items:  center;
  align-content:  center;
  font-weight:  bold;
}

span.is--titel {
  font-size: 13px;
}

.is--vacation {
  background: #b4343e !important;
  color: white;
  text-align: center;
}
.is--absetzer {
  background: #3c763d !important;
  color: white;
  text-align: center;
}
.is--sickday {
  background: #1791ff!important;
  color: white;
  text-align: center;
}
.is--holiday {
  background:  #333!important;
  color: white;
  text-align: center;
}
.is--weekend {
  background: rgba(255, 75, 70, 0.8)!important;
  color: white;
  text-align: center;
}

.cal--nav .nav-pills >li {
  background: #3c8dbc;
  color:  white;
}

.cal--nav .nav-pills >li a {
  color:  white;
  padding: 7px 15px;
}

.cal--nav .nav-pills >li a:hover {
  background:  red;
  border-top-color:  red;
}

.cal--nav .nav-pills >li.active a {
  background:  red;
  border-top-color:  red;
}

.cal--nav .nav-pills >li.active a:hover {
  background: #3c8dbc;
  border-top-color:  #3c8dbc;
}