.file--wrapper {
  display:  flex;
  justify-content: flex-start;
  align-items:  center;
  align-content:  center;
  flex-wrap:  wrap;
  padding:  2rem;
  /* box-shadow: inset 0px 1px 20px 0px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05); */
}

.file--box {
  height: 12rem;
  width: 12rem;
  background:  white;
  margin-right: 2rem;
  font-size: 8rem;
  display:  flex;
  justify-content:  center;
  align-items:  center;
  align-content:  center;
  flex-wrap:  wrap;
  position:  relative;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05);
  background: #eee;
}

.file--box--name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  max-height: 100%;
  word-wrap: break-word;
  text-align: center;
  font-weight: 600;
  background: rgba(255, 255, 255, .6);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .15);
  font-size: 12px;
  padding: 0.3rem;
}