.is--projekt {
  background: #3c8dbc;
  color: white
}
.is--tool {
  background: #00a65a;
  color: white
}
.is--car {
  background: #f39c12;
  color: white;
}
.is--termin {
  background: #605ca8;
  color: white;
}
.is--urlaub {
  background: #29a800;
  color: white;
}

.is--krank {
  background: #a89600;
  color: white;
}

.is--feiertag {
  background: #0da80f;
  opacity: .3;
}
.is--square {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.is--project-date {
  background: rgba(13, 168, 15, .5);
}
tr.item-list.not-saved {
  background: #f2dede;
}

.height--320 {
  min-height: 320px;
}

ul.replacment-variables-list {
  list-style: none;
  padding: 6px;
}

.replacment-variables-list > li {
  padding: 0.5rem;
}
span.replacment-variable {
  background: cornsilk;
}
.btn-toolbar .btn-group .btn {
  margin-right: 5px;
}

.table--zeiten .is--feiertag {
  background: #0da80f;
  opacity: 1;
}

.table--zeiten .is--weekend-table {
  background: rgba(60, 141, 188, 0.3) !important;
}
.table--zeiten .is--sickday,
.table--zeiten .is--absetzer,
.table--zeiten .is--vacation {
  color: inherit !important;
  text-align: inherit !important;
}
.btn-artikel-header > *{
  margin-right: 5px;
}
.btn-artikel-header {
  display:  flex;
  justify-content:  flex-end;
  align-items: flex-start;
}

.btn-group.pull-right.btn-artikel-header {}

.add-itemlist-wrapper {
  display:  flex;
  justify-content:  flex-start;
  align-items:  center;
}