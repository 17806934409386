.msg-modal {
  font-size: 15px;
  position: fixed;
  z-index: 10000;
  bottom: 60px;
  right: 10px;
  width: auto;
  margin-top: 0;
  text-align: center;
  color: white;
  padding: 2rem;
  transition: 2s;
}
.msg-modal.msg-hide {
  right: -1000px;
  transition: 2s;
}
.msg-error{
  background: rgba(221, 75, 57, 1);
}
.msg-success {
  background: rgba(75, 152, 5, 1);
}
.msg-danger{
  background: rgba(221, 75, 57, 1);
}
.msg-info {
  background: rgba(0, 115, 183, 1);
}
.msg-warning{
  background: rgba(243, 156, 18, 1);
}
.msg-default{
  background: rgba(0, 115, 183, 1);
}


